import { useEffect, useRef } from 'react';
import * as THREE from 'three';
import WebGL from 'three/addons/capabilities/WebGL.js';
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import { RndFontTxt } from '../components/RndFontTxt';
import { getNextColor } from '../utils/colors';

export const WelcomePage = () => {

  const refContainer = useRef(null);

  let i = 0;

  useEffect(() => {

    if (i>0) return;
    i++;
    
    if (!WebGL.isWebGLAvailable()){
      const warning = WebGL.getWebGLErrorMessage();
      refContainer.current.appendChild( warning );
      return;
    }

    const scene = new THREE.Scene();
    const aspect =  window.innerHeight / window.innerWidth;
    let camera;
    const renderer = new THREE.WebGLRenderer( { alpha: true } );
    renderer.setSize(window.innerWidth, window.innerHeight);
    refContainer.current && refContainer.current.appendChild( renderer.domElement );

    renderer.toneMapping = THREE.ACESFilmicToneMapping;
    renderer.toneMappingExposure = 0.5
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = THREE.PCFSoftShadowMap; // default THREE.PCFShadowMap

    const geometry = new THREE.BoxGeometry(1, 1, 1,);
    const material = new THREE.MeshBasicMaterial({color: 0xffffff})
    const cube = new THREE.Line(geometry, material);

    const clock = new THREE.Clock()
    let mixer;

    //scene.add(cube);

    cube.scale.multiplyScalar(3);

    const loader = new GLTFLoader();

    let duckModel;
    const screens = [];

    loader.load('/me/me.gltf', function ( gltf ) {

      duckModel = gltf.scene;

      scene.add( duckModel );

      camera = gltf.cameras[0];

      camera.left = (camera.bottom + 0.5) / aspect;
      camera.right = (camera.top + 1.5) / aspect;

      camera.bottom = -2;
      camera.top = 2;

      camera.updateProjectionMatrix();

      mixer = new THREE.AnimationMixer(gltf.scene)

      gltf.animations.forEach((clip) => {
        mixer.clipAction(clip).play();
      });

      gltf.scene.traverse((child) => {
        if (child.isLight) {
          child.intensity *= 0.01;
        }
        if(child.name.search(/^screen/i) != -1){
          screens.push(child);
        }
      });

    }, undefined, function ( error ) {
    
      console.error( error );
    
    } );


    const light = new THREE.AmbientLight( 0xffffff, 1 );
    //scene.add( light );

    function animate(){
      if(!camera) return;
      mixer.update( clock.getDelta() );
      
      screens.forEach(child => {

        const color = getNextColor(clock.getDelta() * 10000);
        child.material.emissive = {isColor: true, r: color[0], g: color[1], b: color[2]}
      })
      renderer.render(scene, camera)
    }
    renderer.setAnimationLoop(animate);
  }, []);

  return <div style={{height: "100vh", width: "100vw"}}>
    <div className='welcome' ref={refContainer} style={{position: 'absolute', top: 0, zIndex: -1}}></div>
    <div className='welcomeDiv'>
    Hi!<br/>
    I'm <RndFontTxt finalFont="fantasy" timer={600} style={{fontSize: "128px"}}>J</RndFontTxt>
    <RndFontTxt finalFont="birthstone-bounce-regular" timer={1200} style={{fontSize: "188px"}}>A</RndFontTxt>
    <RndFontTxt finalFont="jacquard-24-regular" switchTime={100} timer={2000} style={{fontSize: "180px"}}>N</RndFontTxt>
    <span style={{fontSize: "200px"}}>!</span>
    <br/>
    <div>
      Software developer, tech enthusiast and <span style={{color: "cyan"}}>more</span>!
    </div>
    </div>
  </div>
}