import { createRef, useEffect } from 'react';
import '../utils/fonts.css';

export const RndFontTxt = ({ style, children, finalFont, timer = 2000, switchTime = 150}) => {

  const fontClasses = ["danfo", "fantasy", "birthstone-bounce-regular", "jacquard-24-regular"];

  let ref = createRef();

  useEffect(() => {
    fontRaffle(0, 0);
  })
  
  function fontRaffle(time, i){
    setTimeout(() => {
      if(time > timer){
        setTimeout(() => ref.current.className = finalFont, switchTime*2);
        return;
      }
      ref.current.className = fontClasses[Math.floor(Math.random()*fontClasses.length)];
      fontRaffle(time + switchTime, i+1)
    }, switchTime)
  }

  return <span ref={ref} className={fontClasses[Math.floor(Math.random()*fontClasses.length)]} style = {style}>{children}</span>
}