import * as echarts from 'echarts/core';

import { TreeChart } from 'echarts/charts';

import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent
} from 'echarts/components';

import { LabelLayout, UniversalTransition } from 'echarts/features';

import { CanvasRenderer } from 'echarts/renderers';

import { useEffect, useRef } from 'react';

const LVL1 = {color: '#FF0000'};
const LVL2 = {color: '#FF7F00'};
const LVL3 = {color: '#EFFF00'};
const LVL4 = {color: '#00FF00'};

const PARENT_SYMBOL = 'circle';

export const TechStack = () => {

    const zoomAmountRef = useRef(1);

  const treeData = {
    name: "Technologies",
    symbol: PARENT_SYMBOL,
    children: [
        {
            name: "Web dev (used in experience)",
            symbol: PARENT_SYMBOL,
            children: [
                { name: 'HTML5',
                itemStyle: LVL4 },
                { name: 'CSS',
                itemStyle: LVL4 },
                { name: 'Javascript',
                itemStyle: LVL4 },
                { name: 'Typescript',
                itemStyle: LVL4 },
                { name: 'React',
                itemStyle: LVL4 },
                { name: 'Redux',
                itemStyle: LVL3 },
                { name: 'REST api',
                itemStyle: LVL4 },
                { name: 'GraphQL',
                itemStyle: LVL2 },
                { name: 'Node.js',
                itemStyle: LVL3 },
                { name: 'Express.js',
                itemStyle: LVL3 },
            ]
        },
        {
            name: "Mobile dev (used in experience)",
            symbol: PARENT_SYMBOL,
            children: [
                { name: 'React Native',
                itemStyle: LVL4 },
                { name: 'Expo',
                itemStyle: LVL4 },
                { name: 'Android Studio',
                itemStyle: LVL3 },
            ]
        },
        {
            name: "Tools",
            symbol: PARENT_SYMBOL,
            children: [
                { name: 'Figma',
                itemStyle: LVL4 },
                { name: 'GitHub',
                itemStyle: LVL4 },
                { name: 'Git',
                itemStyle: LVL4 },
                { name: 'UML',
                itemStyle: LVL4 },
                { name: 'Linux',
                itemStyle: LVL3 },
            ]
        },
        {
            name: "Hobby (used in personal projects)",
            symbol: PARENT_SYMBOL,
            children: [
                { name: 'Blender [expandable]',
                itemStyle: LVL3,
                children: [
                  {name: "Modelling",
                  itemStyle: LVL4,},
                  {name: "UV Mapping",
                  itemStyle: LVL4,},
                  {name: "Rigging",
                  itemStyle: LVL4,},
                  {name: "Animation",
                  itemStyle: LVL4,},
                  {name: "Texturing",
                  itemStyle: LVL4,},
                  {name: "Retopology",
                  itemStyle: LVL3,},
                  {name: "Geometry nodes",
                  itemStyle: LVL2,}
                ]},
                { name: 'Unity',
                itemStyle: LVL4 },
                { name: 'Unity Netcode',
                itemStyle: LVL4 },
                { name: 'Unity Relay',
                itemStyle: LVL4 },
                { name: 'Three.js',
                itemStyle: LVL4 },
                { name: 'WebSocket',
                itemStyle: LVL3 },
            ]
        },
        {
            name: "Explored for Learning",
            symbol: PARENT_SYMBOL,
            children: [
                { name: 'Java',
                itemStyle: LVL1 },
                { name: 'Springboot',
                itemStyle: LVL1 },
                { name: 'WebRTC',
                itemStyle: LVL1 },
                { name: 'C',
                itemStyle: LVL2 },
                { name: 'OpenGL',
                itemStyle: LVL1 },
                { name: 'AWS(EC2 Deployment)',
                itemStyle: LVL1 },
            ]
        }
    ]
}

  useEffect(() => {

    echarts.use([
      TreeChart,
      TitleComponent,
      TooltipComponent,
      GridComponent,
      DatasetComponent,
      TransformComponent,
      LabelLayout,
      UniversalTransition,
      CanvasRenderer
    ]);

    var myChart = echarts.init(document.getElementById('stackTree'));

    // Draw the chart
    myChart.setOption({
      tooltip: {},
      
    series: [
      {
          type: 'tree',
          data: [treeData],
          top: '5%',
          left: '7%',
          bottom: '2%',
          right: '20%',
          symbolSize: 15,
          symbol: 'diamond',
          label: {
              color: "#ffffff",
              position: 'left',
              verticalAlign: 'middle',
              align: 'right',
          },
          leaves: {
              label: {
                  position: 'right',
                  verticalAlign: 'middle',
                  align: 'left',
              },
          },
          expandAndCollapse: true,
          animationDuration: 550,
          animationDurationUpdate: 750,
          roam: true // Enable panning and zooming
      }
  ]
    });
    myChart.on('treeroam', e => {
        console.log(e, zoomAmountRef.current)
        if(!e.zoom) return;
        let tempval = zoomAmountRef.current + e.zoom-1;
        zoomAmountRef.current = tempval
        if(tempval < 1) tempval = 1
            myChart.setOption({
          
                series: [
                  {
                      label: {
                          fontSize: 12*tempval*0.9,
                      }
                  }
              ]});
    })
  },[]);

  return <div style={{marginLeft: "2%",marginRight: "2%"}}>
      <div id="stackTree" style={{height: "80vh"}}/>
      <div style={{position: 'absolute', bottom: "2vh"}}><span style={LVL4}>■</span> used frequently, <span style={LVL3}>■</span> used occasionally, <span style={LVL2}>■</span> long time no see, <span style={LVL1}>■</span> used once/twice</div>
    </div>
}