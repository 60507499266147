const colors = [
  [0, 0, 0],    // Black
  [0, 0, 0],    // Black
  [1, 0.3, 0],    // Red (replacing 0 with 0.3)
  [1, 1, 0],      // Yellow
  [0.3, 1, 0],    // Lime (replacing 0 with 0.3)
  [0.3, 0.3, 1],  // Blue (replacing all 0 with 0.3)
  [0.5, 0.3, 1]   // Purple (replacing 0 with 0.3)
  // Add more colors as needed
];

let currentIndex = 0;
let currentStep = 0;
const transitionDuration = 100; // Transition duration in milliseconds

export const getNextColor = (deltaTime) => {
  const startColor = colors[currentIndex];
  
  if(currentStep >= transitionDuration){
    currentIndex++;
    if(currentIndex >= colors.length) currentIndex = 0;
    currentStep = 0;
  }

  currentStep = currentStep + deltaTime;

  const endColor = colors[(currentIndex + 1) % colors.length];

  const lerp = (a, b, amount) => (1 - amount) * a + amount * b;

  const r = lerp(startColor[0], endColor[0], currentStep / transitionDuration)
  const g = lerp(startColor[1], endColor[1], currentStep / transitionDuration)
  const b = lerp(startColor[2], endColor[2], currentStep / transitionDuration)

  return [r, g, b]
}