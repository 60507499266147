import { Outlet } from "react-router-dom";
import { NavBtn } from "../components/NavBtn";

export const Main = () => <>
  <header>
    <div id="navName">Jan Skládal</div>
    <div id="navMenu">
      <NavBtn href={"/"}>Home</NavBtn>
      <NavBtn href={"projects"}>Projects</NavBtn>
      <NavBtn href={"techstack"}>Tech stack</NavBtn>
      <NavBtn href={"cv"}>CV</NavBtn>
    </div>
    <div style={{position: "absolute", bottom: '2vh', left: '2vh'}}>:]</div>
  </header>
  <Outlet/>
</>