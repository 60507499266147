import { Route, Routes } from 'react-router-dom';
import './App.css';
import { Main } from './pages/Main';
import { CV } from './pages/CV';
import { Projects } from './pages/Projects';
import { NoPage } from './pages/NoPage';
import { WelcomePage } from './pages/WelcomePage';
import { TechStack } from './pages/TechStack';

function App() {
  return (
    <Routes>
      <Route path='/' element={<Main/>}>
        <Route path='/' element={<WelcomePage/>} />
        <Route path='/cv' element={<CV/>} />
        <Route path='/projects' element={<Projects/>} />
        <Route path='/techstack' element={<TechStack/>} />
        <Route path='*' element={<NoPage/>} />
      </Route>
    </Routes>
  );
}

export default App;
