export const Projects = () => <div className="projectsDiv" style={{textAlign: "center"}}>
  <br/>
  CURRENTLY WORKING ON:<br/>
  Saloon Showdown - Multiplayer web game (development + design) - <a href="https://github.com/JanSkla/saloon-showdown-front">public github repository</a><br/>
  <img src="cowboy_default_export.gif" alt="cowboy man" width={200}/>
  <br/>
  RELEASED PERSONAL PROJECTS:<br/>
  <a href="https://janskla.itch.io/branchbrawl/">Branch Brawl</a> - 3D multiplayer PvP shooter + <a href="https://a2020sklaja.delta-www.cz/maturita/Branch_Brawl.pdf">Documentation (CZ)</a><br/>
  <a href="https://alexu16.itch.io/pstros">PStros</a> - quiz app (android)<br/>
  <a href="https://dastardlydog.com/creators-dastardly-dog/">Dastardly Dog</a> - design for card game<br/>
  ...<br/>

  <br/>
  EXPERIENCES:<br/>
  ...
  <div style={{backgroundColor: "red", color: "black", fontWeight: "bold", fontSize: "64px"}}>⚠UNDER CONSTRUCTION⚠</div>
</div>